import React from "react";
import ProductPage from "./ProductPage";

const Product = () => {
  return (


      <ProductPage />

  );
};

export const Head = () => <title>Shop</title>;

export default Product;
